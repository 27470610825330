.App{
    height: 100%;
	background-color: RGB(var(--bg-color));
	display: block;
	color: RGB(var(--text-color));
}

.headwrap{
    filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.3));
}

.headboard{
    position: fixed;
    height: calc(88px + 3.14rem);
    width: 100%;
    background-color: RGB(var(--header-bg));
    top: 0;
    left: 0;
    clip-path: ellipse(57% 100% at 50% 0%);
}

.ie11 .headboard{
    height: calc(60px + 3.14rem);
    box-shadow: 0px 5px 10px 0 RGBA(0, 0, 0, .3);
}

.app-container{
	display: flex;
    flex-direction: column;
	height: 100%;
	padding-left: 0;
}

.overlay{
	height: 100%;
	width: 100%;
	background-color: RGBA(var(--overlay-color), .15);
	top: 0;
	left: 0;
	display: block;
	z-index: -9999;
	position: fixed;
}

*, :after, :before {
	box-sizing: border-box;
}

@media screen and (max-width : 768px){
    .sidebar.open ~ .overlay{
        z-index: 9999;
    }
}

@media screen and (min-width : 768px){

    .app-container.logged{

        .main-content, .header{
    		padding-left: 60px;
        }
        .sidebar.open ~ .main-content, .sidebar.open ~ .header{
    		padding-left: 18.57143rem;
        }

    }
    /*
    .App:not(.open) .app-container.logged{
        padding-left: 60px;
    }
    */
}
