.header {
  background-color: rgb(var(--header-bg));
  height: 66px;
  position: relative;
  width: 100%;
  z-index: 999;
  top: 0;
}

.header .header-wrap {
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  min-height: inherit;
  padding: 0.71429rem 1rem;
  max-height: 60px;
}

.header-wrap .toggle {
  background: none;
  border: none;
}

.header-wrap .toggle svg {
  color: RGBA(var(--header-color));
}

.myuser {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: RGBA(var(--header-color));
}

.header .username {
  font-weight: bold;
  color: RGB(var(--header-color));
  margin-left: 10px;
}
