.dropdown-menu{
	z-index: 1000;
	display: flex;
	flex-direction: column;
	float: left;
	min-width: 10rem;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: RGB(var(--header-color));
	text-align: left;
	list-style: none;
	background-color: RGB(var(--header-bg));
	background-clip: padding-box;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.2), 3px 3px 3px 0 rgba(240, 240, 240, .1) inset;

	> a{
		text-decoration: none;
		color: RGB(var(--header-color));
	}

	> .dropdown-item {
		display: block;
		width: 100%;
		padding: .75rem 1.5rem;
		clear: both;
		font-weight: 400;
		text-align: inherit;
		white-space: nowrap;
		background-color: transparent;
		cursor: pointer;
		font-weight: bold;
		transition: background-color ease-in-out .4s;


		&.active, &:hover{
			background-color: RGBA(var(--bg-color), .2);
		}


		&:not(:last-child){
			border-bottom : 1px solid RGBA(var(--main-accent-color-dark), .3);
		}
	}


}
