.error-screen{
	display : flex;
	background-color : RGBA(var(--error-bg),0);
	height : 100%;
	width : 100%;
	background-image: url(../../Assets/logo.png);
	background-repeat: no-repeat;
    background-position: 95% 2%;
    color: RGB(var(--error-color));
    padding-left: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

	> h1{
		font-size: 10rem;
		margin: 2rem 0;
	}

	> .error-line{
		display: block;
		height: 2px;
		background-color: RGB(var(--error-color));
		width: 50%;
	}

	> p{
		margin: 1rem 0;
		font-size: 2rem;
	}

}
