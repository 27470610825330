.main-content{
	height: 100%;
	width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
	will-change: padding;
    transition: padding .3s ease-in-out 0s;
}


@media screen and (min-width : 768px){
	.logged .main-content{
	    padding-top: 1rem;
	}
}
