#modal-root{
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	overflow: hidden;
    color: RGB(var(--text-color));

	.modal-container{
		clip-path: circle(0);
		transition: clip-path ease-in-out .5s;

		&.in{
			clip-path: circle(100%);
		}
	}

	.modal-fade{
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		//animation: fade-in 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

		background-color: rgba(var(--overlay-color),.5);
		/*&.fading{
			animation: fade-out 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both !important;
		}*/
	}

	.modal-overlay{
		//animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
		margin: 0 auto;
		width: max-content;
		max-width: 100vw;
		z-index: 2;
		position: relative;
		display: flex;
		max-height: 100vh;

		.modal-content{
			align-items: stretch;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			width: 100%;
			overflow: auto;
			max-height: 100%;
			overflow-x: hidden;
			min-width: 80vw;

			> *:not(.close-button){
				margin: 0 !important;
				padding: 10px !important;
			}

			> .close-button{
				border-radius: 50%;
				height: 30px;
				width: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 25px;
				right: 0px;
				background: RGB(var(--header-color));
				color: RGB(var(--header-bg));
		    	box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3), 2px 2px 4px 0 rgba(240, 240, 240, 0.15) inset;
				cursor: pointer;
				z-index: 9;
			}
		}

		/*&.fading{
			animation: puff-out-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both !important;
		}*/

	}




}
