.dropdown-wrapper{
	position: relative;

	> .dropdown-trigger{
		cursor: pointer;
	}

	> .dropdown-content{
		position: absolute;
		z-index: 1000;
		float: left;
		width: max-content;
		will-change: transform;
        clip-path: circle(0% at 100% 0);
        transition: all ease-in-out .5s;

        &.active{
            clip-path: circle(140% at 100% 0);
        }

        &.ie11{
            display: none;

            &.active{
                display: block;
    		}
        }
	}
}
