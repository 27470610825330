.form-card{
	align-items: stretch;
    background: RGB(var(--card-color));
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 0rem;
    width: 100%;
	margin: 1rem 0;


	@media (max-width: 768px){
		padding: 2.1rem;
		margin: 2.1rem auto .7rem;
	}


	.form-title{
		@media (max-width: 767px){padding: 0;}

		align-items: baseline;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.4rem;
		padding-bottom: 0.5rem;
		width: 100%;
	}


	> .form-body{
		padding: 30px;
		width: 100%;
		position: relative;
	}

}
