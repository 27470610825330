.hero-card{
	align-items: stretch;
    background: RGBA(var(--hero-card-color), var(--hero-card-alpha));
	box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.3), 3px 3px 6px 0 rgba(240, 240, 240, 0.15) inset;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 2.9rem;
    padding: 6.4rem 7.1rem;
    width: 100%;


	@media (max-width: 768px){
		padding: 2.1rem;
		margin: 2.1rem auto .7rem;
	}


	> .hero-title{
		@media (max-width: 767px){padding: 0;}
		color: RGBA(var(--hero-card-text-color));
	    align-items: baseline;
		display: flex;
		justify-content: space-between;
		padding-left: 1.8rem;
		width: 100%;
		font-size: 1.8em;
    	letter-spacing: .1px;
	}


	> .hero-body{
		width: 100%;
		position: relative;
	}

}
